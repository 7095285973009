<template>
  <div class="swiper-container" style="text-align: center;width:550px;">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="item in date" style="text-align: center">
        <div class="selectDate">
          <div class="date" :class="{actived:item.actived}" @click="clickDate(item)" >
            <div>{{ item.day }}</div>
            <div>
              <span>{{ item.month }}</span>&nbsp;
              <span>{{ item.date }}</span>
            </div>
            <div class="underline" v-show="item.actived"></div>
          </div>
        </div>
      </div>
      <!--<div class="swiper-slide">Slide 2</div>-->
      <!--<div class="swiper-slide">Slide 3</div>-->
      <!--<div class="swiper-slide">Slide 4</div>-->
      <!--<div class="swiper-slide">Slide 5</div>-->
      <!--<div class="swiper-slide">Slide 6</div>-->
      <!--<div class="swiper-slide">Slide 7</div>-->
      <!--<div class="swiper-slide">Slide 8</div>-->
      <!--<div class="swiper-slide">Slide 9</div>-->
      <!--<div class="swiper-slide">Slide 10</div>-->
    </div>
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>

<script>
import {Swiper} from 'swiper';
export default {
  name: "datePick",
  props: {
    date: {
      type: Array
    }
  },
  data() {
    return {
      // date: [
      //   {day: 'MON', month: 'FEB', date: 1, actived: true},
      //   {day: 'TUE', month: 'FEB', date: 2, actived: false},
      //   {day: 'WED', month: 'FEB', date: 3, actived: false},
      //   {day: 'THU', month: 'FEB', date: 4, actived: false},
      //   {day: 'FRI', month: 'FEB', date: 5, actived: false},
      //   {day: 'SAT', month: 'FEB', date: 6, actived: false},
      //   {day: 'SUN', month: 'FEB', date: 7, actived: false},
      // ]
    }
  },
  methods: {
    clickDate(item) {
      console.log('item:', item);
      this.date.map(d => {
        if ((item.date == d.date) && (item.month == d.month)) {
          d.actived = true;
        } else {
          d.actived = false;
        }
      })
      this.$emit('clickDate',item);
    }
  },
  mounted() {
    console.log('date!!!!!!!!!',this.date);
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 7,
      spaceBetween: 30,
      onlyExternal:true,
      loop: false,
      loopFillGroupWithBlank: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

}


</script>

<!--<style>-->
<!--html,-->
<!--body {-->
<!--  position: relative;-->
<!--  height: 100%;-->
<!--}-->

<!--body {-->
<!--  background: #eee;-->
<!--  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;-->
<!--  font-size: 14px;-->
<!--  color: #000;-->
<!--  margin: 0;-->
<!--  padding: 0;-->
<!--}-->

<!--.swiper-container {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--}-->

<!--.swiper-slide {-->
<!--  text-align: center;-->
<!--  font-size: 18px;-->
<!--  background: #fff;-->

<!--  /* Center slide text vertically */-->
<!--  display: -webkit-box;-->
<!--  display: -ms-flexbox;-->
<!--  display: -webkit-flex;-->
<!--  display: flex;-->
<!--  -webkit-box-pack: center;-->
<!--  -ms-flex-pack: center;-->
<!--  -webkit-justify-content: center;-->
<!--  justify-content: center;-->
<!--  -webkit-box-align: center;-->
<!--  -ms-flex-align: center;-->
<!--  -webkit-align-items: center;-->
<!--  align-items: center;-->
<!--}-->
<!--</style>-->

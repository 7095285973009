<template>
  <div class="selectDate">
    <i class="iconfont icon-zuojiantou" @click="leftArrow" v-if="date.length > 7 "></i>
    <div class="outBox" :style="{width:'560px',overflow: 'hidden'}">
      <div class="dataContainer"
           :style="{textAlign:'center',
         width:(date.length)*80+'px',
         marginLeft:num*80+'px',
         overflow:'hidden',
         boxSizing:'border-box'}"
      >
        <div class="date"
             style="width:80px"
             :class="{actived:item.actived}"
             @click="clickDate(item)"
             @keyup.enter="clickDate(item)"
             v-for="(item,index) in date"
             :tabindex="index+1"
        >
          <div>{{ item.day }}</div>
          <div>
            <span>{{ item.month }}</span>&nbsp;
            <span>{{ item.date }}</span>
          </div>
          <div class="underline" v-show="item.actived"></div>
        </div>
      </div>
    </div>
    <i class="iconfont icon-youjiantou" @click="rightArrow" v-if="date.length > 7 "></i>
  </div>
</template>

<script>
export default {
  name: "datePick",
  props: {
    date: {
      type: Array
    }
  },
  data() {
    return {
      num: 0,
      // date: [
      //   {day: 'MON', month: 'FEB', date: 1, actived: true},
      //   {day: 'TUE', month: 'FEB', date: 2, actived: false},
      //   {day: 'WED', month: 'FEB', date: 3, actived: false},
      //   {day: 'THU', month: 'FEB', date: 4, actived: false},
      //   {day: 'FRI', month: 'FEB', date: 5, actived: false},
      //   {day: 'SAT', month: 'FEB', date: 6, actived: false},
      //   {day: 'SUN', month: 'FEB', date: 7, actived: false},
      // ]
    }
  },
  methods: {
    clickDate(item) {
      // console.log('item:', item);
      this.date.map(d => {
        if ((item.date == d.date) && (item.month == d.month)) {
          d.actived = true;
        } else {
          d.actived = false;
        }
      })
      this.$emit('clickDate', item);
    },
    leftArrow() {
      if (this.date.length < 8) {
        return
      }
      if (this.num < 0) {
        this.num += 1;
      }
    },
    rightArrow() {
      if (this.date.length < 8) {
        return
      }
      if (this.num > -(this.date.length + this.num)) {
        this.num -= 1;
      }
    }
  },

}
</script>

<style scoped lang="scss">
.selectDate {
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    cursor: pointer;
    z-index: 9999;
  }

  .dataContainer {
    display: flex;

    .date {
      margin: 0 10px;
      cursor: pointer;

      &.actived {
        font-weight: bold;
      }
      .underline {
        width: 100%;
        height: 2px;
        //background: $main-blue;
        //background: #4164FD;
      }

    }
  }

}
</style>

